import React from 'react';
import { Handle, NodeResizer, Position } from 'reactflow';
import CustomHandle from '../CustomGates/CustomHandle';

export default function MicroController({ data }) {
  //   console.log('data', data);
  return (
    <>
      <NodeResizer />
      <div>
        <CustomHandle type="target" position={Position.Top} style={{ top: '15px', opacity: 0 }} isConnectable={1} />
        {/* <div>{data?.label}</div> */}
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          height="150px"
          width="100%"
          viewBox="0 0 624 576"
        >
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M207.539459,545.329712 
	C207.541275,524.044373 207.554459,503.224518 207.564911,482.404633 
	C207.570023,472.238525 207.599335,462.072266 207.561783,451.906311 
	C207.538193,445.520508 207.012283,445.016174 200.783386,445.013519 
	C184.450974,445.006622 168.118423,445.052429 151.786209,444.997131 
	C147.082520,444.981232 142.363785,444.688965 138.962341,440.711853 
	C136.724716,438.095459 135.556946,435.133362 135.615738,431.570374 
	C135.750443,423.406403 135.661133,415.238647 135.651260,407.072418 
	C135.643173,400.382172 135.635803,400.364990 129.102402,400.363922 
	C97.104118,400.358765 65.105820,400.384521 33.107578,400.349579 
	C25.369284,400.341125 20.111116,395.849792 19.691177,389.127014 
	C19.192757,381.147827 24.124266,375.696991 32.209328,375.652710 
	C49.041256,375.560516 65.874016,375.620605 82.706436,375.619843 
	C98.372261,375.619141 114.040367,375.472260 129.702469,375.713928 
	C134.254074,375.784149 135.792511,374.464661 135.745804,369.797363 
	C135.535599,348.800598 135.533356,327.799194 135.759399,306.802734 
	C135.808319,302.257965 134.217224,301.198303 129.977783,301.216858 
	C97.813461,301.357697 65.648232,301.310974 33.483318,301.280121 
	C25.566780,301.272522 20.429592,297.119934 19.721369,290.361755 
	C18.882702,282.358856 24.055792,276.689270 32.408504,276.678925 
	C63.906769,276.639832 95.405106,276.665131 126.903412,276.655945 
	C136.618317,276.653107 135.642715,277.387085 135.655365,267.657349 
	C135.678345,249.991653 135.545242,232.324646 135.740967,214.661118 
	C135.787140,210.494537 134.464264,209.234070 130.332733,209.251175 
	C98.001717,209.384933 65.669838,209.346420 33.338310,209.298798 
	C24.661610,209.286011 19.508020,204.251831 19.680067,196.235901 
	C19.800402,190.629303 23.386280,186.003845 29.021637,184.806381 
	C31.923399,184.189819 34.973545,184.075256 37.957176,184.070572 
	C68.288788,184.022827 98.620506,184.052536 128.952194,184.040497 
	C135.634964,184.037842 135.643860,184.001419 135.651337,177.455414 
	C135.660263,169.622513 135.616547,161.789398 135.660416,153.956726 
	C135.712112,144.725113 140.536194,139.820694 149.904495,139.770554 
	C167.069733,139.678680 184.239700,139.545731 201.399231,139.873398 
	C206.593201,139.972565 207.794220,138.320190 207.763306,133.351624 
	C207.555908,100.021378 207.633301,66.689186 207.675842,33.357697 
	C207.687180,24.480053 212.496475,19.487993 220.588257,19.675354 
	C227.335968,19.831596 232.160004,24.468046 232.329956,31.354639 
	C232.510803,38.683155 232.335495,46.019695 232.398758,53.352016 
	C232.634521,80.677948 232.000885,108.006569 232.720535,135.333755 
	C232.817215,139.005005 234.085052,139.838623 237.392029,139.823853 
	C258.723785,139.728500 280.056915,139.673203 301.387695,139.844833 
	C305.791290,139.880280 306.656677,137.996613 306.645966,134.128967 
	C306.556366,101.797646 306.606689,69.465935 306.615936,37.134346 
	C306.616455,35.301178 306.574829,33.466133 306.641449,31.635183 
	C306.885376,24.930826 311.433807,20.002222 317.632935,19.676226 
	C325.087891,19.284187 330.411743,23.531927 331.247162,30.639370 
	C331.440643,32.285587 331.345734,33.968323 331.345947,35.634262 
	C331.350250,68.299179 331.441071,100.964645 331.247559,133.628418 
	C331.219177,138.418427 332.565094,139.923721 337.405457,139.859421 
	C356.067810,139.611542 374.736572,139.651535 393.400238,139.844589 
	C397.646149,139.888504 398.759338,138.373337 398.745056,134.338715 
	C398.625702,100.674294 398.656097,67.009209 398.705841,33.344421 
	C398.719086,24.383707 403.457092,19.475107 411.595367,19.676090 
	C418.439209,19.845106 423.184570,24.391941 423.309357,31.351034 
	C423.479645,40.847546 423.375610,50.349091 423.377167,59.848480 
	C423.381226,84.513817 423.485260,109.180038 423.282745,133.843704 
	C423.245422,138.389648 424.481659,139.939224 429.150879,139.869278 
	C445.813049,139.619644 462.480988,139.748962 479.146759,139.749359 
	C490.781464,139.749649 495.290405,144.189926 495.311035,155.649384 
	C495.324249,162.982300 495.629059,170.332962 495.213654,177.642151 
	C494.921692,182.779831 496.748444,184.207230 501.724304,184.174942 
	C532.054749,183.978134 562.386963,184.040970 592.718567,184.061920 
	C595.373108,184.063751 598.063477,184.170456 600.674805,184.604156 
	C607.758118,185.780640 611.716553,190.871994 611.311401,197.973663 
	C610.949036,204.326401 605.908936,209.075317 598.941406,209.269150 
	C592.446716,209.449875 585.943237,209.324127 579.443665,209.324371 
	C553.278381,209.325363 527.112488,209.409882 500.948425,209.234985 
	C496.646667,209.206223 495.148438,210.323883 495.204742,214.825150 
	C495.440338,233.654587 495.421204,252.489624 495.235260,271.320038 
	C495.194092,275.487396 496.519806,276.748962 500.644623,276.731812 
	C532.809021,276.598267 564.974243,276.653015 597.139160,276.667389 
	C606.320801,276.671478 611.353577,281.072845 611.314514,288.984192 
	C611.276855,296.613770 605.892883,301.282318 596.890564,301.291565 
	C565.058960,301.324249 533.226807,301.399139 501.396362,301.203796 
	C496.598541,301.174347 495.141296,302.511292 495.200958,307.366577 
	C495.456909,328.195679 495.451416,349.031128 495.221008,369.860718 
	C495.168945,374.564331 496.692322,375.745270 501.222534,375.721191 
	C533.553162,375.549103 565.885193,375.647278 598.216736,375.620483 
	C603.725525,375.615906 608.038269,377.668793 610.311279,382.795166 
	C612.362793,387.421844 611.712097,391.966156 608.505127,395.934509 
	C605.517883,399.630890 601.361755,400.393555 596.836975,400.386383 
	C565.338806,400.336487 533.840454,400.359650 502.342133,400.358734 
	C495.320465,400.358521 495.320709,400.357269 495.316040,407.527924 
	C495.310944,415.360840 495.383453,423.194763 495.279144,431.026367 
	C495.164703,439.622742 490.484222,444.299408 481.889801,444.334930 
	C464.724365,444.405884 447.558411,444.357147 430.392670,444.365143 
	C423.425049,444.368408 423.400757,444.377014 423.398254,451.150360 
	C423.385895,484.481903 423.402405,517.813416 423.374664,551.144958 
	C423.366760,560.644714 419.353119,564.570801 410.036377,564.374451 
	C403.262421,564.231689 398.837402,559.771484 398.734222,552.472168 
	C398.578735,541.474915 398.692169,530.473999 398.690826,519.474609 
	C398.688019,496.475830 398.557648,473.475830 398.784119,450.479309 
	C398.832153,445.602020 397.253113,444.210907 392.510010,444.268372 
	C374.013702,444.492615 355.511993,444.470673 337.014893,444.284668 
	C332.681213,444.241089 331.245575,445.565521 331.265747,449.987549 
	C331.419220,483.651703 331.368378,517.316895 331.332336,550.981750 
	C331.322571,560.128052 326.826874,564.540344 317.929565,564.377625 
	C311.461670,564.259399 306.977051,560.099121 306.681854,553.540710 
	C306.449799,548.384766 306.619171,543.210510 306.618134,538.044434 
	C306.612335,509.545959 306.617523,481.047516 306.599304,452.549042 
	C306.594177,444.512756 306.421539,444.361084 298.609558,444.360321 
	C278.443970,444.358398 258.276855,444.495544 238.113953,444.263885 
	C233.444321,444.210236 232.206238,445.702515 232.229355,450.245331 
	C232.399811,483.742676 232.339859,517.241272 232.313416,550.739441 
	C232.306107,559.984375 227.890945,564.482910 219.122772,564.377502 
	C212.346069,564.296021 208.008621,560.151428 207.626892,553.275879 
	C207.488800,550.788513 207.569077,548.289062 207.539459,545.329712 
M470.635162,310.500061 
	C470.635590,309.333984 470.636658,308.167938 470.636353,307.001862 
	C470.623962,261.848022 470.606445,216.694199 470.608490,171.540359 
	C470.608643,167.882889 470.803345,164.907516 465.316803,164.915527 
	C365.678711,165.061172 266.040314,165.044922 166.402115,164.933167 
	C161.568024,164.927734 160.242889,166.510727 160.250839,171.267288 
	C160.385330,251.744217 160.349091,332.221405 160.353577,412.698547 
	C160.353958,419.655701 160.363602,419.675507 167.563919,419.676117 
	C266.369202,419.684570 365.174469,419.688202 463.979736,419.688751 
	C470.544067,419.688812 470.569397,419.672974 470.574921,412.971222 
	C470.602692,379.147522 470.616211,345.323792 470.635162,310.500061 
z"
          />
          <text x="200" y="255" style={{ fontSize: '3rem', textShadow: 'none', maxWidth: '100px' }}>
            {data?.label}
          </text>
        </svg>
        <Handle type="source" position={Position.Bottom} style={{ bottom: '10px', opacity: 0 }} />
      </div>
    </>
  );
}
